<template>
    <section id="skills">
      <div class="cha-header-clip" aria-hidden="true">
        <header class="cha-header">
          <NavDark/>
        </header>
        <div class="cha-header-section">
          <NavSectionDarkTwins
            :linkUp="'#'"
            :linkDown="'#portfolio'"
          />
        </div>
      </div>
      <div class="container padding-top">
        <div class="mt-5">
          <h2>Compétences</h2>
          <p class="col-md-8">Outre l'inséparable trio que forment <strong>Html</strong>, <strong>Css</strong> et <strong>Javascript</strong> je travaille actuellement sur un stack full js avec Node et Vue en particulier.
          </p>
          <p>Je pratique toujours le Php régulièrement, avec Laravel ou lorsque je modifie et déplois des solutions Wordpress ou Prestashop.</p>
          <div>
            <ul class="row col-md-5 mx-auto text-center">
              <div v-for="(icon, index) in this.iconBasicSkillArray" :key="index" class="mx-auto">
                <li>
                  <SkillItem
                    class="medium-icon"
                    :iconName="icon"
                    :iconWrapperClass="'p-3 fit-content mx-auto'"
                  />
                </li>
              </div>
            </ul>
          </div>
          <div class="row smooth-card">
            <ul class="col-4 list-group text-center">
              <h5>Je travail avec au quotidien</h5>
              <div v-for="(icon, index) in this.iconDailySkillArray" :key="index">
                <li
                  class="my-2"
                >
                  <SkillItem
                    class="medium-icon"
                    :height="50"
                    :iconName="icon"
                  />
                </li>
              </div>
            </ul>
            <ul class="col-4 list-group text-center">
              <h5>J'ai eu à faire</h5>
              <div v-for="(icon, index) in this.iconOftenSkillArray" :key="index">
                <li
                  class="my-2"
                >
                  <SkillItem
                    class="medium-icon"
                    :iconName="icon"
                  />
                </li>
              </div>
            </ul>
            <ul class="col-4 list-group text-center">
              <h5>J'aime particulièrement</h5>
              <div v-for="(icon, index) in this.iconPassionSkillArray" :key="index">
                <li
                  class="my-2"
                >
                  <SkillItem
                    class="medium-icon"
                    :iconName="icon"
                  />
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
</template>

<style scoped>
    
</style>

<script>
    import NavDark from '@/components/Layouts/Navdark'
    import NavSectionDarkTwins from '@/components/Layouts/NavSectionDarkTwins'
    import SkillItem from '@/components/SkillItem'

    export default {
        components: {
            NavDark,
            NavSectionDarkTwins,
            SkillItem
        },
        props: {
        },
        data() {
            return {
              iconBasicSkillArray: {
                boostrap: {
                  name: 'bootstrap',
                  displayName: 'Bootstrap',
                  alt: 'Bootstrap'
                },
                html: {
                  name: 'html',
                  displayName: 'Html 5',
                  alt: 'Html 5'
                },
                css: {
                  name: 'css3',
                  displayName: 'Css 3',
                  alt: 'Css 3'
                },
                javascript: {
                  name: 'javascript',
                  displayName: 'Javascript',
                  alt: 'Javascript'
                },
              },
              iconDailySkillArray: {
                nodejs: {
                  name: 'nodejs',
                  displayName: 'Node js',
                  alt: 'Node Js'
                },
                git: {
                  name: 'git',
                  displayName: 'Git',
                  alt: 'Git, Github & Gitlab'
                },
                vue: {
                  name: 'vue',
                  displayName: 'Vue Js',
                  alt: 'Vue js'
                },
                docker: {
                  name: 'docker',
                  displayName: 'Docker',
                  alt: 'Docker'
                },
                sql: {
                  name: 'sql',
                  displayName: 'My Sql',
                  alt: 'My Sql'
                },
                tailwind: {
                  name: 'tailwind',
                  displayName: 'Tailwind',
                  alt: 'Tailwind'
                },
                npm: {
                  name: 'npm',
                  displayName: 'Npm',
                  alt: 'Npm'
                },
              },
              iconOftenSkillArray: {
                php: {
                  name: 'php',
                  displayName: 'Php',
                  alt: 'Php'
                },
                symfony: {
                  name: 'symfony',
                  displayName: 'Symfony',
                  alt: 'Symfony'
                },
                laravel: {
                  name: 'laravel',
                  displayName: 'Laravel',
                  alt: 'Laravel'
                },
                wordpress: {
                  name: 'wordpress',
                  displayName: 'Wordpress',
                  alt: 'Wordpress'
                },
                prestashop: {
                  name: 'prestashop',
                  displayName: 'Prestashop',
                  alt: 'Prestashop'
                },
              },
              iconPassionSkillArray: {
                blender: {
                  name: 'blender',
                  displayName: 'Blender',
                  alt: 'Blender'
                },
                photoshop: {
                  name: 'photoshop',
                  displayName: 'Photoshop',
                  alt: 'Photoshop'
                },
                illustrator: {
                  name: 'illustrator',
                  displayName: 'Illustrator',
                  alt: 'Illustrator'
                },
                xd: {
                  name: 'xd',
                  displayName: 'Xd',
                  alt: 'Xd'
                },
                
              },
              
            }
        },
        methods:{
            displayExperience(){
                let switchButton = document.getElementsByClassName('switch-btn');
                console.log(switchButton);
                switchButton.forEach(element => {
                    element.classList.toggle("switch-outline-btn");
                    element.classList.toggle("switch-full-btn");
                });

                if(this.timelineState == true){
                    this.timelineState = false;
                }else if(this.timelineState == false){
                    this.timelineState = true;
                }
            }
        }

    }

</script>
